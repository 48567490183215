/**
 * @author liuzhenkun
 * @date 2017/12/27-下午3:42
 * @file help-center.js
 */

const TabMap = {
    loan: {
        name: 'loan',
        url: '/webview/help-center/loan'
    },
    repayment: {
        name: 'repayment',
        url: '/webview/help-center/repayment'
    }
};

export default {
    name: 'HelpCenter',

    data() {
        return {
            TabMap,
            activeTab: ''
        };
    },

    methods: {
        handleTabChange(currentTab) {
            const vm = this;
            vm.activeTab = currentTab;
            if (global) return;
            document.body.scrollTop = 0;
        }
    },

    created() {
        const vm = this;

        if (vm.$route.path === TabMap.repayment.url) {
            vm.handleTabChange(vm.TabMap.repayment);

            return;
        }

        vm.handleTabChange(vm.TabMap.loan);
    }
};
