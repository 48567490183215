var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "help-center" },
    [
      _c(
        "div",
        { staticClass: "tabs-wrapper" },
        [
          _c(
            "router-link",
            {
              staticClass: "tab-item",
              attrs: { "active-class": "tab-active", to: _vm.TabMap.loan.url },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleTabChange(_vm.TabMap.loan)
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("webview.helpCenter.loan")) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "tab-item",
              attrs: {
                "active-class": "tab-active",
                to: _vm.TabMap.repayment.url,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleTabChange(_vm.TabMap.repayment)
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("webview.helpCenter.repayment")) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c("span", {
            staticClass: "tab-highlight",
            class: {
              "tab-highlight-active":
                _vm.activeTab.name === _vm.TabMap.repayment.name,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "tab-placeholder" }),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }